import { fetchPostDocument } from "../../shared/helpers/documentOptions";

export const ExpenseReceiptUploadMixin = {
  methods: {
    async expenseReceiptUploadMixin(document, userId, institutionId) {
      try {
        const results = await fetchPostDocument(document, 1, userId, institutionId)
        return results.id
      } catch (error) {
        console.error('Error in expenseReceiptUploadMixin:', error);
      }
    }
  }
}