import { mapState } from 'vuex';
// import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import { dateUtilsMixin } from '../../shared/mixins/dateUtilsMixin';
import { fileUtilsMixin } from '../../shared/mixins/fileUtilsMixin';
import { getItem } from '@/api/api-methods';
import { postExpense, putExpense } from '../helpers/utilsExpense';
import { validationFormMixin } from '../../shared/mixins/validationFormMixin';
import { ExpenseReceiptUploadMixin } from '../mixins/ExpenseReceiptUploadMixin';
import { AccountTransactionMixin } from '../../shared/mixins/AccountTransactionMixin';
import { MOVEMENT_TYPES_ACCOUNT } from '../../shared/helpers/statesMovementsTypesAccount';

import KnBackToTopButton from '../../shared/components/KnBackToTopButton.vue';
import KnCheckBox from '../../shared/components/KnCheckBox.vue';
import KnDocItem from '../../shared/components/KnDocItem.vue';
import KnDownloadDocItem from '../../shared/components/KnDownloadDocItem.vue';
import KnFormActionButtons from '../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormTitle from '../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../shared/components/KnSelect.vue';
import KnTextField from '../../shared/components/KnTextField.vue';
export default {
  components: {
    // KnFormSubtitle,
    KnFormActionButtons,
    KnDocItem,
    KnLocalAlert,
    KnDownloadDocItem,
    KnFormTitle,
    KnBackToTopButton,
    KnTextField,
    KnSelect,
    KnCheckBox,
    KnFormNoteOfMandatory,
  },
  mixins: [
    dateUtilsMixin,
    fileUtilsMixin,
    validationFormMixin,
    ExpenseReceiptUploadMixin,
    AccountTransactionMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueDeterminate: 100,
      routerName: 'Egresos',
      valid: true,
      expense: {
        proveedor: null,
        empleado: null,
        categoria: null,
        sub_categoria: null,
        sub_total: 0.0,
        total_impuestos: 0.0,
        costo_envio: 0.0,
        total_descuento: 0.0,
        total_egreso: 0.0,
        descuento_aplicado: false,
        forma_de_pago: null,
        moneda: 1,
        cuenta_origen: null,
        estatus: null,
        //Cambiar variables escalabilidad
        centro_de_costos: 1,
        autor: 1,
        institucion_educativa: 1,
      },
      hasTaxes: false,
      expenseId: null,
      receiver: null,
      suppliers: [],
      employees: [],
      expenseCategories: [],
      expenseSubcategories: [],
      paymentMethods: [],
      coins: [],
      bankAccounts: [],
      expenseStatus: [],
      costCenter: [],
      institutions: [],
      authors: [],
      loading: false,
      showAlert: false,
      alertType: 'info',
      alertText: 'Registro exitoso',
      alertColor: null,
      expenseDate: null,
      receipt: null,
      receiptIdToDelete: null,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null
        ? 'Registro de egresos'
        : 'Detalle de egresos';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Egreso registrado con exito!'
        : 'Egreso actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    showDownload() {
      return !this.isNewMode && this.receipt !== null && this.receipt.id;
    },
    showUpload() {
      return (
        this.receipt === null || (this.receipt !== null && !this.receipt.id)
      );
    },
    taxSuffix() {
      return this.hasTaxes ? '16%' : '0%';
    },
    mandatoryTaxRules() {
      return this.hasTaxes ? [this.rules.required] : [];
    },
  },
  async created() {
    try {
      this.loading = true;
      this.alertText = 'Cargando... Por favor, espera';
      this.showAlert = true;

      const [
        suppliersRes,
        employeesRes,
        expenseCategoriesRes,
        expenseSubcategoriesRes,
        paymentMethodsRes,
        coinsRes,
        expenseStatusRes,
        bankAccountsRes,
        institutionsRes,
      ] = await Promise.all([
        getItem(`/app-personas/filters/proveedor?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-personas/filters/empleado?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/categoria-egreso?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/sub-categoria-egreso?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/forma-de-pago?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/moneda?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/estatus-egreso?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/cuenta?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`),
        getItem(`/app-personas/institucion-educativa`),
      ]);

      this.suppliers = suppliersRes.results;
      this.employees = employeesRes.results;
      this.expenseCategories = expenseCategoriesRes.results;
      this.expenseSubcategories = expenseSubcategoriesRes.results;
      this.paymentMethods = paymentMethodsRes.results;
      this.coins = coinsRes.results;
      this.expenseStatus = expenseStatusRes.results;
      this.bankAccounts = bankAccountsRes.results;
      this.institutions = institutionsRes.results;

      const today = new Date();
      this.expenseDate = this.getDate(today);

      if (!this.isNewMode) {
        // console.log('Se deben llenar los campos');
        this.expenseId = this.entity.id;
        this.fillExpense();
      }
    } catch (error) {
      console.error('Error al cargar datos:', error);
      this.showAlert = false;
    } finally {
      this.loading = false;
      this.showAlert = false;
    }
  },
  methods: {
    async save() {
      this.loading = true;
      this.alertText = 'Cargando...';
      this.showAlert = true;

      try {
        if (this.isNewMode) {
          const receipts = await this.createReceiptDocument();
          const expense = await postExpense(this.expense, receipts);
          if (!expense)
            throw new Error('No se pudo realizar el registro del egreso');

          await this.createAccountMovement();
        } else {
          this.expense.subcategoria = this.expense.sub_categoria;
          putExpense(this.expense, this.expenseId);
          if (this.receiptIdToDelete !== null) {
            await this.removeReceiptFromExpense(
              this.receiptIdToDelete,
              this.expenseId
            );
            await this.deleteReceiptPermanently(this.receiptIdToDelete);
          }
          if (this.errors.length === 0 && this.receipt !== null && this.receipt.file) {
            await this.createReceiptDocument();
          }
        }

        this.alertType = this.errors.length ? 'error' : this.successAlertType;
        this.alertColor = this.errors.length ? 'error' : 'success';
        this.alertText = this.errors.length ? this.errors.join(', ') : this.successAlertText;

      } catch (error) {
        this.alertType = 'error';
        this.alertText = error.message;
      } finally {
        this.loading = false;
      }
    },

    async createAccountMovement() {
      const movement = await this.createAccountTransaction(
        this.userData.id, 
        this.expense.cuenta_origen,
        this.expense.total_egreso.toFixed(2),
        MOVEMENT_TYPES_ACCOUNT.DECREASE,
      )
      if (movement) {
        this.alertText = 'Movimiento de inventario creado con éxito';
      } else {
        throw new Error('No se pudo crear el movimiento de inventario.');
      }
    },

    calculateTotal() {
      let {
        sub_total,
        total_impuestos,
        total_descuento,
        total_egreso
      } = this.expense;

      total_impuestos = sub_total * (this.hasTaxes ? 0.16 : 0.0);
      total_egreso = sub_total + total_impuestos - total_descuento;
      this.expense.total_impuestos = total_impuestos;
      this.expense.total_egreso = total_egreso;
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.showAlert = false;
    },
    clean() {
      this.expense = {
        proveedor: null,
        empleado: null,
        categoria: null,
        sub_categoria: null,
        sub_total: 0.0,
        total_impuestos: 0.0,
        costo_envio: 0.0,
        total_descuento: 0.0,
        total_egreso: 0.0,
        descuento_aplicado: false,
        forma_de_pago: 1,
        moneda: 1,
        cuenta_origen: null,
        estatus: 1,
        centro_de_costos: 1,
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
    },
    fillExpense() {
      this.expenseDate = this.getDateWithTimeZeroFullStr(
        this.entity.fecha_elaboracion
      );
      this.receiver = this.entity.proveedor
        ? 'proveedor'
        : this.entity.empleado
          ? 'empleado'
          : null;
      // console.log('Destinatario:', this.receiver);
      this.expense = {
        id: this.entity.id,
        proveedor: this.entity.proveedor ? this.entity.proveedor.id : null,
        empleado: this.entity.empleado ? this.entity.empleado.id : null,
        categoria: this.entity.categoria.id,
        sub_categoria: this.entity.sub_categoria.id,
        sub_total: this.entity.sub_total,
        total_impuestos: this.entity.total_impuestos
          ? this.entity.hasTaxes
          : this.entity.hasTaxes,
        costo_envio: this.entity.costo_envio,
        total_descuento: this.entity.total_descuento,
        total_egreso: this.entity.total_egreso,
        descuento_aplicado: this.entity.descuento_aplicado,
        forma_de_pago: this.entity.forma_de_pago.id,
        moneda: this.entity.moneda.id,
        cuenta_origen: this.entity.cuenta_origen.id,
        estatus: this.entity.estatus.id,
        centro_de_costos: this.entity.centro_de_costos.id,
        autor: this.userData.id, // Debe ser el usuario logueado
        institucion_educativa: this.institutionId, // Debe ser la institucion del usuario logueado
      };
      // console.log('Total impuestos ', this.entity.total_impuestos);
      // console.log('Total impuestos ', this.entity.hasTaxes);
      this.receipt =
        this.entity.comprobantes.length > 0
          ? this.entity.comprobantes[0]
          : null;
      this.fillRFC();
    },
    saveReceipt(file) {
      this.receipt = file;
    },
    clearReceipt(file) {
      this.receipt = null;
      if (!this.isNewMode && file.id !== null) {
        this.receiptIdToDelete = file.id;
      }
    },
    async createReceiptDocument() {
      try {
        if (this.receipt && this.receipt.file) {
          const receipt = await this.expenseReceiptUploadMixin(this.receipt.file, this.userData.id, this.institutionId);
          if (receipt) {
            return receipt;
          }
        }
      } catch (error) {
        console.log(error);
        this.errors.push('No se pudo asignar el comprobante de ingreso');
      }
    },

    fillRFC() {
      let person = null;
      switch (this.receiver) {
        case 'empleado':
          person = this.employees.find(
            (employee) => employee.id === this.expense.empleado
          );
          this.expense.rfc = person.datos_fiscales.tax_id;
          break;
        case 'proveedor':
          person = this.suppliers.find(
            (supplier) => supplier.id === this.expense.proveedor
          );
          this.expense.rfc = person.datos_fiscales[0].tax_id;
          break;
        default:
          this.expense.rfc = '';
      }
    },
  },
};
